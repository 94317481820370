import { useRef } from 'react'
import PropTypes from 'prop-types'

import { useStyletron } from 'baseui'

import { StyledHeader, StyledIcon, StyledTitle } from './styled-components'
import iconLogo from './unknown--filled.svg'

const PanelNew = props => {
  const [css] = useStyletron()
  const panelRef = useRef()
  const logo = props.logo ?? iconLogo
  const alt = props.alt ?? 'Alternative text...'
  const title = props.title ?? 'Random child'
  const headerContent = props.headerContent ?? null

  if (props.empty === true) {
    return <div ref={panelRef} />
  }

  return (
    <div
      className={css({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        alignSelf: 'stretch',
        marginBottom: '32px',
      })}
      ref={panelRef}
      id={props.id}
    >
      <StyledHeader>
        <StyledIcon src={logo} alt={alt} />
        <StyledTitle>{title}</StyledTitle>
        {headerContent && <StyledTitle>{headerContent}</StyledTitle>}
      </StyledHeader>

      {props.children}
    </div>
  )
}

PanelNew.propTypes = {
  alt: PropTypes.string,
  children: PropTypes.node,
  empty: PropTypes.bool,
  id: PropTypes.string,
  logo: PropTypes.node,
  title: PropTypes.string,
  type: PropTypes.string,
  headerContent: PropTypes.func,
}

export default PanelNew
