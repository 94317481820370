import PropTypes from 'prop-types'
import { useState } from 'react'
import { Tabs, Tab } from 'baseui/tabs-motion'
import Client from './Tabs/Client'
import useSubscriptionForm from './useSubscriptionForm'
import SecondTab from './Tabs/SecondTab'
import ThirdTab from './Tabs/ThirdTab'

const SubscriptionForm = ({ id, loadViewParametersRoute, country, saveRoute }) => {
  const [activeKey, setActiveKey] = useState('0')
  useSubscriptionForm({ applicationType: 'energie', initUrl: loadViewParametersRoute, id, saveRoute })

  const tabStyle = {
    Tab: {
      style: ({ $isActive }) => ({
        borderRadius: '0.5rem 0.5rem 0 0',
        marginRight: '0.3rem',
        backgroundColor: $isActive ? 'white' : 'lightgrey',
        fontWeight: 'bold',
        fontSize: '1rem',
      }),
    },
    TabPanel: {
      style: () => ({
        backgroundColor: 'white',
        borderRadius: '0 0.5rem 0.5rem 0.5rem',
        padding: '24px',
        display: 'flex',
        alignItems: 'flex-start',
        gap: '48px',
        alignSelf: 'stretch',
      }),
    },
  }
  const TabContentStyle = {
    TabBorder: {
      style: () => ({
        height: '0rem',
      }),
    },
    TabHighlight: {
      style: () => ({
        display: 'none',
      }),
    },
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'inline-flex',
        gap: '24px',
        alignItems: 'flex-start',
      }}
    >
      <div
        style={{
          width: '75%',
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}
      >
        <Tabs
          activeKey={activeKey}
          onChange={({ activeKey }) => {
            setActiveKey(activeKey)
          }}
          overrides={TabContentStyle}
          activateOnFocus
        >
          <Tab title='Client' overrides={tabStyle}>
            <Client />
            <SecondTab />
            <ThirdTab />
          </Tab>
          <Tab title='+ Ajouter' overrides={tabStyle}>
            Another
          </Tab>
        </Tabs>
      </div>
      <div style={{ width: '25%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            marginBottom: '24px',
            backgroundColor: 'white',
            borderRadius: '8px',
            padding: '24px',
          }}
        >
          Credit booster
        </div>
        <div
          style={{
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
            alignItems: 'flex-start',
            marginBottom: '24px',
            backgroundColor: 'white',
            borderRadius: '8px',
            padding: '24px',
          }}
        >
          Aide completion
        </div>
        <div
          style={{
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
            alignItems: 'flex-start',
            marginBottom: '24px',
            backgroundColor: 'white',
            borderRadius: '8px',
            padding: '24px',
          }}
        >
          Historique des ventes
        </div>
      </div>
    </div>
  )
}

SubscriptionForm.propTypes = {
  id: PropTypes.string,
  saveRoute: PropTypes.string,
  token: PropTypes.string,
  country: PropTypes.string,
  loadViewParametersRoute: PropTypes.string,
}

export default SubscriptionForm
