import { Card } from 'baseui/card'
import { SIZE } from 'baseui/input'
import { Notification } from 'baseui/notification'
import { FormControl } from 'baseui/form-control'
import { ChevronRight } from 'baseui/icon'
import { Button, KIND, SIZE as SIZEBTN, SHAPE } from 'baseui/button'
import { useTranslation } from 'react-i18next'

import SDButton from '@/shared/components/SDComponents/SDButton/SDButton'
import SDInput from '@/shared/components/SDComponents/SDInput/SDInput'
import IconGoogle from '@/shared/icons/IconGoogle'
import LogoHOP from '@/shared/icons/LogoHOP'
import useLogin from './useLogin'
import useLoginStyles from './useLoginStyles'

const LoginPage = () => {
  const { t } = useTranslation()

  const { error, formData, login, isLoading, loginGoogle, handleChange, updateCountry, updateLang } = useLogin()
  const { loginWrapper, loginCard, logo, spacer, cardOverrides } = useLoginStyles()

  return (
    <div className={loginWrapper}>
      <main id='main'>
        <section>
          <div className='global-container'>
            <Card className={loginCard} overrides={cardOverrides}>
              <div className={logo}>
                <LogoHOP size={50} />
              </div>

              <div>
                <Button
                  isSelected={formData.country === 'fr'}
                  size={SIZEBTN.mini}
                  kind={KIND.secondary}
                  shape={SHAPE.circle}
                  onClick={() => {
                    updateCountry('fr')
                    updateLang('fr')
                  }}
                  overrides={{
                    BaseButton: {
                      style: ({ $theme }) => ({
                        marginRight: '0.25rem',
                      }),
                    },
                  }}
                >
                  <span>🇫🇷</span>
                </Button>
                <Button
                  isSelected={formData.country === 'be'}
                  size={SIZEBTN.mini}
                  kind={KIND.secondary}
                  shape={SHAPE.circle}
                  onClick={() => {
                    updateCountry('be')
                    updateLang('fr')
                  }}
                >
                  <span>🇧🇪</span>
                </Button>

                <Button
                  isSelected={formData.country === 'it'}
                  size={SIZEBTN.mini}
                  kind={KIND.secondary}
                  shape={SHAPE.circle}
                  onClick={() => {
                    updateCountry('it')
                    updateLang('it')
                  }}
                >
                  <span>🇮🇹</span>
                </Button>
              </div>

              <FormControl label={t('auth:email')} error={formData.emailError}>
                <SDInput size={SIZE.compact} onChange={handleChange} id='email-input' name='email' />
              </FormControl>

              <FormControl label={t('auth:password')}>
                <SDInput
                  size={SIZE.compact}
                  type='password'
                  onChange={handleChange}
                  id='password-input'
                  name='password'
                />
              </FormControl>

              {error && (
                <Notification kind={KIND.negative}>
                  <span id='login-error'>{error}</span>
                </Notification>
              )}

              <SDButton
                isLoading={isLoading}
                kind={KIND.secondary}
                size={SIZE.default}
                block={true}
                endEnhancer={() => <ChevronRight size={24} />}
                onClick={login}
                id='login-button'
              >
                {t('auth:connect')}
              </SDButton>

              <div style={spacer}></div>

              <SDButton
                kind={KIND.secondary}
                size={SIZE.default}
                block={true}
                startEnhancer={() => <IconGoogle size={24} />}
                onClick={loginGoogle}
              >
                {t('auth:google_login')}
              </SDButton>
            </Card>
          </div>
        </section>
      </main>
    </div>
  )
}

export default LoginPage
