export default function optionsToArray(options) {
  const optionsArray = []

  Object.keys(options).forEach(key => {
    optionsArray.push({
      [key]: options[key],
    })
  })
  return optionsArray
}
