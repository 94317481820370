import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import { persistStore, persistReducer } from 'redux-persist'
import { setupListeners } from '@reduxjs/toolkit/query'
import createSagaMiddleware from 'redux-saga'
import Sagas from './Sagas'
import storage from 'redux-persist/lib/storage'

import { rootReducer } from './Reducer'

const persistConfig = {
  key: 'root',
  storage,
  blacklist: [
    'subscriptionForm',
    'subscriptionFormConfiguration',
    'formV2',
    'formConfigurationV2',
    'usersManagement',
    'officesManagement',
    'assistances',
    'monConseillerPersoProducts',
    'carbonOffsets',
    'servicesStatus',
    'pageConfiguration',
  ],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const sagaMiddleware = createSagaMiddleware()

export default preloadedState => {
  const store = configureStore({
    reducer: persistedReducer,
    middleware: [
      ...getDefaultMiddleware({
        serializableCheck: false,
      }),
      sagaMiddleware,
    ],
    preloadedState,
    devTools: !import.meta.env.PROD,
  })

  sagaMiddleware.run(Sagas)

  const persistor = persistStore(store)
  setupListeners(store.dispatch)

  return {
    store,
    persistor,
  }
}
