import * as Sentry from '@sentry/react'
import { useEffect } from 'react'
import { BaseProvider, createTheme } from 'baseui'
import { Provider as StyletronProvider } from 'styletron-react'
import { Client as Styletron } from 'styletron-engine-atomic'
import { PersistGate } from 'redux-persist/integration/react'
import { createRoot } from 'react-dom/client'
import TagManager from 'react-gtm-module'
import { Provider } from 'react-redux'
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom'
import selectraPrimitives from '@/shared/theme/themePrimitives'
import selectraOverrides from '@/shared/theme/themeOverrides'
import gtmConfig from '@/shared/tools/gtmConfig'
import createStore from '@/store/Store'
import CustomRoutes from './routes/routes'
import './i18n'
import Echo from 'laravel-echo'
import Pusher from 'pusher-js'
import { runtimeConfig } from '@/runtime-config'

if (import.meta.env.PROD) {
  Sentry.init({
    dsn: 'https://209d98c2cb444b9f8be8ee71e36122b0@o1158305.ingest.sentry.io/4505289967271936',
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      /^https:\/\/app\.hop\.selectra\.io/,
      /^https:\/\/hop-spa\.s3\.staging\.aws\.selectra\.io/,
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}

const container = document.getElementById('root')
const selectraTheme = createTheme(selectraPrimitives, selectraOverrides)
const styletronEngine = new Styletron()
const root = createRoot(container)

const { store, persistor } = createStore()

window.Pusher = Pusher

window.Echo = new Echo({
  broadcaster: 'reverb',
  key: runtimeConfig.LARAVEL_ECHO_KEY,
  wsHost: runtimeConfig.LARAVEL_ECHO_HOST,
  wsPort: runtimeConfig.LARAVEL_ECHO_PORT,
  wssPort: runtimeConfig.LARAVEL_ECHO_PORT,
  forceTLS: runtimeConfig.LARAVEL_ECHO_TLS,
  enabledTransports: ['ws', 'wss'],
})

if (container) {
  const tagManagerArgs = {
    gtmId: gtmConfig('fr'),
  }
  if (import.meta.env.PROD) {
    TagManager.initialize(tagManagerArgs)
  }
}

root.render(
  <Provider store={store}>
    <PersistGate loading='' persistor={persistor}>
      <StyletronProvider value={styletronEngine}>
        <BaseProvider theme={selectraTheme}>
          <CustomRoutes />
        </BaseProvider>
      </StyletronProvider>
    </PersistGate>
  </Provider>,
)
