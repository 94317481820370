export const modulesConfig = {
  fr: {
    zoho: {
      mcp: true,
    },
    customer: {
      siret: true,
      apeCode: true,
      activity: true,
      bacVoucher: true,
      referral: true,
      taxId: false,
    },
  },
  it: {
    zoho: {
      mcp: false,
    },
    customer: {
      siret: true,
      apeCode: false,
      activity: false,
      bacVoucher: false,
      referral: false,
      taxId: true,
    },
  },
  be: {
    zoho: {
      mcp: false,
    },
    customer: {
      siret: true,
      apeCode: false,
      activity: false,
      bacVoucher: false,
      referral: false,
      taxId: false,
    },
  },
}
