import { createSlice } from '@reduxjs/toolkit'
import subscriptionData from './Common/subscriptionData'
import telecomSubscriptionData from './Telecom/telecomSubscriptionData'
import energySubscriptionData from './Energy/energySubscriptionData'
import insuranceSubscriptionData from './Insurance/insuranceSubscriptionData'
import additionalSalesDefaultState from './Common/additionalSalesDefaultState'

const initialState = {
  common: { ...subscriptionData },
  telecom: { ...telecomSubscriptionData },
  energy: { ...energySubscriptionData },
  insurance: { ...insuranceSubscriptionData },
  additionalSales: { ...additionalSalesDefaultState },
}

const initializeContractDataHandler = (state, action) => {
  state.common = action.payload.common
  state.telecom = action.payload.telecom
  state.energy = action.payload.energy
  state.insurance = action.payload.insurance
  state.additionalSales = action.payload.additionalSales
}

// example of dispatch: dispatch(updateAdditionalSalesData({data: {test: 'test'}, name: 'voltalis'}))
const updateAdditionalSalesDataHandler = (state, action) => {
  state.additionalSales[action.payload.name] = {
    ...state.additionalSales[action.payload.name],
    ...action.payload.data,
  }
}

const updateCustomerDataHandler = (state, action) => {
  state.common.customer = {
    ...state.common.customer,
    ...action.payload,
  }
}

const updateBillingDataHandler = (state, action) => {
  state.common.billing = {
    ...state.common.billing,
    ...action.payload,
  }
}

const updateMainAddressDataHandler = (state, action) => {
  state.common.mainAddress = {
    ...state.common.mainAddress,
    ...action.payload,
  }
}

const updateInvoiceAddressDataHandler = (state, action) => {
  state.common.invoiceAddress = {
    ...state.common.invoiceAddress,
    ...action.payload,
  }
}

const updateElectricityMeterDataHandler = (state, action) => {
  state.energy.elecMeter = {
    ...state.energy.elecMeter,
    ...action.payload,
  }
}

const updateGasMeterDataHandler = (state, action) => {
  state.energy.gasMeter = {
    ...state.energy.gasMeter,
    ...action.payload,
  }
}

const updateEnergyContractDataHandler = (state, action) => {
  state.energy.contract = {
    ...state.energy.contract,
    ...action.payload,
  }
}

const updateEnergyCharacteristicsDataHandler = (state, action) => {
  state.energy.characteristics = {
    ...state.energy.characteristics,
    ...action.payload,
  }
}

const updateTelecomContractDataHandler = (state, action) => {
  state.telecom.contract = {
    ...state.telecom.contract,
    ...action.payload,
  }
}

const updateInsuranceContractDataHandler = (state, action) => {
  state.insurance.contract = {
    ...state.insurance.contract,
    ...action.payload,
  }
}

const updateInsuranceCharacteristicsDataHandler = (state, action) => {
  state.insurance.characteristics = {
    ...state.insurance.characteristics,
    ...action.payload,
  }
}

const updatePropaneContractDataHandler = (state, action) => {
  state.energy.propane = {
    ...state.energy.propane,
    ...action.payload,
  }
}

const updateEnergyConsumptionDataHandler = (state, action) => {
  state.energy.consumption = {
    ...state.energy.consumption,
    ...action.payload,
  }
}

const updateEnergyEstimationDataHandler = (state, action) => {
  state.energy.estimation = {
    ...state.energy.estimation,
    ...action.payload,
  }
}

const updateTelecomCharacteristicsDataHandler = (state, action) => {
  state.telecom.characteristics = {
    ...state.telecom.characteristics,
    ...action.payload,
  }
}

export const SubscriptionFormSlice = createSlice({
  name: 'formV2',
  initialState,
  reducers: {
    initializeContractData: initializeContractDataHandler,
    updateCustomerData: updateCustomerDataHandler,
    updateBillingData: updateBillingDataHandler,
    updateElectricityMeterData: updateElectricityMeterDataHandler,
    updateGasMeterData: updateGasMeterDataHandler,
    updateMainAddressData: updateMainAddressDataHandler,
    updateInvoiceAddressData: updateInvoiceAddressDataHandler,
    updateEnergyContractData: updateEnergyContractDataHandler,
    updateEnergyCharacteristicsData: updateEnergyCharacteristicsDataHandler,
    updateTelecomContractData: updateTelecomContractDataHandler,
    updateTelecomCharacteristicsData: updateTelecomCharacteristicsDataHandler,
    updateInsuranceContractData: updateInsuranceContractDataHandler,
    updateInsuranceCharacteristicsData: updateInsuranceCharacteristicsDataHandler,
    updatePropaneContractData: updatePropaneContractDataHandler,
    updateEnergyConsumptionData: updateEnergyConsumptionDataHandler,
    updateEnergyEstimationData: updateEnergyEstimationDataHandler,
    updateAdditionalSalesData: updateAdditionalSalesDataHandler,
  },
})

export const {
  initializeContractData,
  updateCustomerData,
  updateBillingData,
  updateMainAddressData,
  updateInvoiceAddressData,
  updateElectricityMeterData,
  updateGasMeterData,
  updateEnergyContractData,
  updateEnergyCharacteristicsData,
  updateTelecomContractData,
  updateTelecomCharacteristicsData,
  updateInsuranceContractData,
  updateInsuranceCharacteristicsData,
  updatePropaneContractData,
  updateEnergyConsumptionData,
  updateEnergyEstimationData,
  updateAdditionalSalesData,
} = SubscriptionFormSlice.actions

export default SubscriptionFormSlice.reducer
