import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import { GoogleOAuthProvider } from '@react-oauth/google'

import SidebarMenu from './SidebarMenu/SidebarMenu'
import { StyledSidebarMenu } from './SidebarMenu/styled-components'
import { config } from '@/containers/Login/config'
import useImportScript from '@/shared/hooks/useImportScript'
import Login from '@/containers/Login'
import ErrorPage from '@/containers/ErrorPage'
import { selectAuthData } from '@/containers/Login/AuthSelectors'

const BackofficePage = ({ children }) => {
  const {
    authTokenSession,
    isAdmin,
    isSelectraAdmin,
    country,
    applicationType: applicationTypeAuth,
  } = useSelector(selectAuthData)
  const { loadScript } = useImportScript()
  const locationHook = useLocation()
  const [loggedIn, setLoggedIn] = useState(authTokenSession ?? false)
  const [applicationType, setApplicationType] = useState(applicationTypeAuth)
  const authorizedApplicationTypes = [
    'telecom',
    'energie',
    'users-mngt',
    'ip-addresses-management',
    'contact-page',
    'assurance',
    'call-information',
    'contact',
  ]
  const allowedCountries = ['fr', 'be', 'it']
  const hasErrorType = !authorizedApplicationTypes.includes(applicationType)
  const hasErrorCountry = !allowedCountries.includes(country)
  let wrapperClass = 'wrapper'

  if (location.hostname.includes('localhost') || location.hostname.includes('staging')) {
    wrapperClass = 'wrapper-warning'
  }

  useEffect(() => {
    if (authTokenSession) {
      setLoggedIn(true)
    } else {
      setLoggedIn(false)
    }
  }, [authTokenSession])

  useEffect(() => {
    const splitedPath = locationHook.pathname.split('/')
    const currentType = splitedPath[1] ?? ''

    if (applicationType !== currentType) {
      setApplicationType(currentType)
    }
  }, [locationHook.pathname])

  loadScript('//embed.typeform.com/next/embed.js', false, loggedIn)

  if (!loggedIn) {
    return (
      <GoogleOAuthProvider
        clientId={config.clientId}
        onScriptLoadError={providerResponse => {
          console.log(providerResponse)
        }}
      >
        <Login />
      </GoogleOAuthProvider>
    )
  }

  if (hasErrorType || hasErrorCountry) {
    return (
      <div className={wrapperClass}>
        <aside id='sidebar-menu'>
          <StyledSidebarMenu>
            <SidebarMenu
              applicationType={applicationType}
              isAdmin={isAdmin ?? false}
              isSelectraAdmin={isSelectraAdmin ?? false}
              error={true}
            />
          </StyledSidebarMenu>
        </aside>
        <ErrorPage
          hasErrorType={hasErrorType}
          hasErrorCountry={hasErrorCountry}
          currentType={applicationType}
          currentCountry={country}
        />
      </div>
    )
  }

  return (
    <div className={wrapperClass}>
      <aside id='sidebar-menu'>
        <StyledSidebarMenu>
          <SidebarMenu
            applicationType={applicationType}
            isAdmin={isAdmin ?? false}
            isSelectraAdmin={isSelectraAdmin ?? false}
            error={false}
          />
        </StyledSidebarMenu>
      </aside>

      <main id='main'>
        <section>{children}</section>
      </main>
      <div
        data-tf-chat
        data-tf-tooltip='HOP assistance'
        data-tf-popover='TcAeeiM1'
        data-tf-button-color='#0445AF'
      ></div>
    </div>
  )
}

BackofficePage.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
}

export default BackofficePage
