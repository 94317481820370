import { useState } from 'react'
import PropTypes from 'prop-types'
import { FormControl } from 'baseui/form-control'
import { SIZE } from 'baseui/input'
import SDButtonGroup from '@/shared/components/SDComponents/SDButtonGroup/SDButtonGroup'
import SDButtonGroupButton from '@/shared/components/SDComponents/SDButtonGroup/SDButtonGroupButton'
import SDInput from '@/shared/components/SDComponents/SDInput/SDInput'
import { useTranslation } from 'react-i18next'

const ReferralContact = ({
  onChangeReferralFirstName,
  onChangeReferralSecondName,
  firstNamecurrentValue,
  secondNamecurrentValue,
  disabled,
}) => {
  const { t } = useTranslation(),
    initialState = !!(firstNamecurrentValue || secondNamecurrentValue),
    [showReferral, setReferral] = useState(initialState),
    selected = showReferral ? [0] : []

  return (
    <>
      <FormControl>
        <SDButtonGroup
          disabled={disabled}
          mode={'checkbox'}
          size={SIZE.compact}
          selected={selected}
          onClick={(event, index) => {
            event.preventDefault()
            if (!selected.includes(index)) {
              setReferral(true)
            } else {
              setReferral(false)
            }
          }}
        >
          <SDButtonGroupButton>{t('subscriptionForm:customer_module.referral.checkbox')}</SDButtonGroupButton>
        </SDButtonGroup>
      </FormControl>
      {showReferral && (
        <>
          <FormControl label={t('subscriptionForm:customer_module.referral.firstname')}>
            <SDInput
              disabled={disabled}
              size={SIZE.compact}
              value={firstNamecurrentValue}
              onChange={e => onChangeReferralFirstName(e.target.value)}
            />
          </FormControl>

          <FormControl label={t('subscriptionForm:customer_module.referral.lastname')}>
            <SDInput
              disabled={disabled}
              size={SIZE.compact}
              value={secondNamecurrentValue}
              onChange={e => onChangeReferralSecondName(e.target.value)}
            />
          </FormControl>
        </>
      )}
    </>
  )
}

ReferralContact.propTypes = {
  onChangeReferralFirstName: PropTypes.func,
  onChangeReferralSecondName: PropTypes.func,
  firstNamecurrentValue: PropTypes.string,
  secondNamecurrentValue: PropTypes.string,
  disabled: PropTypes.bool,
}

export default ReferralContact
