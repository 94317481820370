import PanelNew from '@/containers/SubscriptionFormPageNew/Panel/PanelNew'
import { FormControl } from 'baseui/form-control'
import { SIZE } from 'baseui/input'
import SDInput from '@/shared/components/SDComponents/SDInput/SDInput'
import logo from '../../Components/ZohoId/zoho-logo.svg'

const ThirdTab = () => {
  return (
    <div style={{ width: '100%' }}>
      <PanelNew alt='third bloc' title='third bloc' logo={logo} id='CustomerModule'>
        <FormControl label='another field'>
          <SDInput id='anInput' disabled={false} size={SIZE.compact} placeholder='some data' autoComplete={'off'} />
        </FormControl>
        <FormControl label='another field'>
          <SDInput id='anInput' disabled={false} size={SIZE.compact} placeholder='some data' autoComplete={'off'} />
        </FormControl>
        <FormControl label='another field'>
          <SDInput id='anInput' disabled={false} size={SIZE.compact} placeholder='some data' autoComplete={'off'} />
        </FormControl>
      </PanelNew>
    </div>
  )
}

export default ThirdTab
