import PropTypes from 'prop-types'
import { useStyletron } from 'baseui'
import { Spinner } from 'baseui/spinner'
import { FormControl } from 'baseui/form-control'
import SDButton from '@/shared/components/SDComponents/SDButton/SDButton'
import useNewsletterField from './useNewsletterField'
import PanelInfoBlock from '@/shared/components/Panel/bones/PanelInfoBlock'

const NewsletterBonsPlans = ({ isNewsletterRegistered, disabled }) => {
  const [css] = useStyletron()
  const {
    emailHasError,
    sendNewsletter,
    email,
    pdlNo,
    isDisabled,
    errorMessage,
    t,
    newsletterLoading,
    newsletterData,
    lang,
  } = useNewsletterField(disabled)

  return (
    <>
      {lang === 'fr' && !pdlNo && !isNewsletterRegistered && (
        <PanelInfoBlock type={'warning'}>
          {t('subscriptionForm:customer_module.newsletter.not_personalized_warning')}
        </PanelInfoBlock>
      )}
      <FormControl
        label={
          pdlNo && lang === 'fr'
            ? t('subscriptionForm:customer_module.newsletter.label_personalized')
            : t('subscriptionForm:customer_module.newsletter.label')
        }
        error={errorMessage}
      >
        <SDButton
          block
          isLoading={newsletterLoading}
          disabled={isDisabled || emailHasError}
          onClick={sendNewsletter}
          overrides={{
            BaseButton: {
              style: () => ({
                backgroundColor: 'orange',
                ':hover': {
                  backgroundColor: 'darkorange',
                },
              }),
            },
          }}
        >
          {!isDisabled
            ? t('subscriptionForm:customer_module.newsletter.button')
            : t('subscriptionForm:customer_module.newsletter.button_submitted') + '(' + email + ')'}
        </SDButton>
      </FormControl>

      {newsletterLoading && (
        <div
          className={css({
            alignItems: 'center',
            display: 'flex',
          })}
        >
          <Spinner size='32px' />
          <p
            className={css({
              marginLeft: '10px',
            })}
          >
            {t('subscriptionForm:customer_module.newsletter.loading_info')}
          </p>
        </div>
      )}

      {newsletterData.exception && <PanelInfoBlock type={'warning'}>{newsletterData.exception}</PanelInfoBlock>}

      {newsletterData.response && <PanelInfoBlock type={'positive'}>{newsletterData.response.message}</PanelInfoBlock>}

      {emailHasError.length > 0 && (
        <PanelInfoBlock type={'negative'}>{t('subscriptionForm:customer_module.email.error')}</PanelInfoBlock>
      )}
    </>
  )
}

NewsletterBonsPlans.propTypes = {
  isNewsletterRegistered: PropTypes.bool,
  disabled: PropTypes.bool,
}

export default NewsletterBonsPlans
