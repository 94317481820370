import { call, cancelled, put, select } from 'redux-saga/effects'
import { selectAuthData } from '@/containers/Login/AuthSelectors'
import { selectPageConfigurationData } from '@/store/PageConfiguration/PageConfigurationSelectors'
import getAPIConfig from '@/shared/tools/getAPIConfig'
import {
  addCommonFormErrors,
  deleteCommonFormErrors,
  updateZohoModuleConfigurationV2,
} from '@/containers/SubscriptionFormPageNew/SubscriptionForm/FormConfigurationV2Slice'

export const zohoModuleActions = {
  ZOHO_MODULE_VERIFY_ID_V2: 'ZOHO_MODULE_VERIFY_ID_V2',
  ZOHO_MODULE_UPDATE_ID_V2: 'ZOHO_MODULE_UPDATE_ID_V2',
  ZOHO_MODULE_UNLOCK_MCP_CLIENT_V2: 'ZOHO_MODULE_UNLOCK_MCP_CLIENT_V2',
}

export function* verifyZohoId({ zohoId }) {
  const controller = new AbortController()
  const { authTokenSession, lang } = yield select(selectAuthData)
  const { verify_zoho_id_url: url } = yield select(selectPageConfigurationData)
  const fullUrl = `${url}?lang=${lang}&value=${encodeURIComponent(zohoId)}`

  try {
    const config = yield call(getAPIConfig, { method: 'get', authTokenSession })
    const signal = controller.signal
    const response = yield call(fetch, fullUrl, { ...config, signal })
    const isValid = response.status === 200
    const { error, isMcp, isMcpLocked } = yield call([response, response.json])

    yield put(
      updateZohoModuleConfigurationV2({
        isValidZohoId: isValid,
        isMcpClient: isMcp ?? false,
        isMcpLocked: isMcpLocked ?? false,
      }),
    )

    if (isValid) {
      yield put(deleteCommonFormErrors('zoho_id'))
    } else {
      yield put(addCommonFormErrors({ zoho_id: [error] }))
    }
  } catch (error) {
    console.warn('verifyZohoIdError', error)
  } finally {
    if (yield cancelled()) {
      controller.abort()
      console.log('http call in verifyZohoId aborted')
    }
  }
}

export function* updateZohoId({ zohoId, updateZohoIdCallback }) {
  const { authTokenSession, lang } = yield select(selectAuthData)
  const { zoho_update_url: url } = yield select(selectPageConfigurationData)

  try {
    const config = yield call(getAPIConfig, { method: 'post', authTokenSession, body: { zoho_id: zohoId } })
    const response = yield call(fetch, url + '?lang=' + lang, config)
    const json = yield call([response, response.json])
    yield call(updateZohoIdCallback, json)
  } catch (error) {
    console.warn('updateZohoIdError', error)
  }
}

export function* unlockMcpClient({ zohoId, t, setUnlockError }) {
  setUnlockError(null)

  const { authTokenSession, lang } = yield select(selectAuthData)
  const { unlock_mcp_zoho_url: url } = yield select(selectPageConfigurationData)
  const fullUrl = url.replace('*', zohoId) + '?lang=' + lang

  try {
    const config = yield call(getAPIConfig, { method: 'put', authTokenSession })
    const response = yield call(fetch, fullUrl, config)
    const isValid = response.status === 200
    const { error_message: errorMessage } = yield call([response, response.json])
    if (!isValid) {
      setUnlockError(errorMessage ?? t('subscriptionForm:zoho_module.error_unblocking_mcp_zoho'))

      return
    }
    yield put(updateZohoModuleConfigurationV2({ isMcpLocked: false }))
  } catch (error) {
    console.warn('unlockMcpClientError', error)
  }
}
