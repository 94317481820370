import { HOPButtonGroup } from '@/shared/components/HOPComponents/HOPButtonGroup'
import { FormControl } from 'baseui/form-control'
import optionsToArray from '@/shared/tools/optionsToArray'

const ClientType = ({
  clientTypeOptions,
  clientType,
  updateClientTypeHandler,
  mandatory,
  errorText,
  label,
  isEditable,
}) => {
  return (
    <FormControl label={label + mandatory} error={errorText}>
      <HOPButtonGroup
        disabled={isEditable === false}
        onClickHandler={value => updateClientTypeHandler(value)}
        options={optionsToArray(clientTypeOptions)}
        selectedValue={clientType}
      />
    </FormControl>
  )
}

export default ClientType
