import { FormControl } from 'baseui/form-control'
import { SIZE } from 'baseui/input'
import PropTypes from 'prop-types'
import { Spinner } from 'baseui/spinner'
import SDInput from '@/shared/components/SDComponents/SDInput/SDInput'
import SDButton from '@/shared/components/SDComponents/SDButton/SDButton'
import { useTranslation } from 'react-i18next'

const Email = ({
  caption,
  captionForceEmail,
  currentValue,
  error,
  isLoading,
  mandatory,
  onChangeHandler,
  removeErrors,
  updateNeverbounce,
  verifyEmail,
  disabled,
}) => {
  const { t } = useTranslation()
  const enhancer = () => {
    if (isLoading) {
      return <Spinner size='18px' />
    }
    return null
  }
  const forceAddress = () => {
    captionForceEmail(true)
    updateNeverbounce('force')
    removeErrors('email')
  }

  return (
    <>
      <FormControl
        label={t('subscriptionForm:customer_module.email.label') + mandatory}
        error={error}
        caption={caption}
      >
        <SDInput
          id='customer-input-email'
          disabled={disabled}
          size={SIZE.compact}
          value={currentValue}
          error={error}
          endEnhancer={enhancer}
          onChange={e => {
            removeErrors('email')
            captionForceEmail(false)
            onChangeHandler(e.target.value)
          }}
          onBlur={verifyEmail}
        />
      </FormControl>
      {error && (
        <FormControl label={t('subscriptionForm:customer_module.email.forceLabel')}>
          <SDButton
            size={SIZE.mini}
            block={true}
            onClick={e => {
              e.preventDefault()
              forceAddress()
            }}
          >
            {t('subscriptionForm:customer_module.email.forceButton')}
          </SDButton>
        </FormControl>
      )}
    </>
  )
}

Email.propTypes = {
  caption: PropTypes.string,
  captionForceEmail: PropTypes.func,
  currentValue: PropTypes.string,
  error: PropTypes.string,
  isLoading: PropTypes.bool,
  mandatory: PropTypes.string,
  onChangeHandler: PropTypes.func,
  removeErrors: PropTypes.func,
  updateNeverbounce: PropTypes.func,
  verifyEmail: PropTypes.func,
  disabled: PropTypes.bool,
}

export default Email
