import { call, cancelled, put, select } from 'redux-saga/effects'
import { selectAuthData } from '@/containers/Login/AuthSelectors'
import { selectPageConfigurationData } from '@/store/PageConfiguration/PageConfigurationSelectors'
import getAPIConfig from '@/shared/tools/getAPIConfig'
import { updateCustomerModuleConfiguration } from '@/containers/SubscriptionFormPageNew/SubscriptionForm/FormConfigurationV2Slice'

export const customerModuleActions = {
  CUSTOMER_MODULE_VERIFY_EMAIL_V2: 'CUSTOMER_MODULE_VERIFY_EMAIL_V2',
  CUSTOMER_MODULE_VERIFY_SIRET_V2: 'CUSTOMER_MODULE_VERIFY_SIRET_V2',
  CUSTOMER_MODULE_REGISTER_TO_NEWSLETTER_V2: 'CUSTOMER_MODULE_REGISTER_TO_NEWSLETTER_V2',
}

export function* verifyEmail({ email, callback }) {
  const controller = new AbortController()
  const { authTokenSession, lang } = yield select(selectAuthData)
  const { verify_email_url: url } = yield select(selectPageConfigurationData)
  const fullUrl = `${url}?lang=${lang}&value=${encodeURIComponent(email)}`
  yield put(updateCustomerModuleConfiguration({ emailIsLoading: true }))
  try {
    const config = yield call(getAPIConfig, { method: 'get', authTokenSession })
    const signal = controller.signal
    const response = yield call(fetch, fullUrl, { ...config, signal })
    const result = yield call([response, response.json])
    yield put(updateCustomerModuleConfiguration({ emailIsLoading: false }))
    yield call(callback, result)
  } catch (error) {
    console.warn('verifyEmailError', error)
  } finally {
    if (yield cancelled()) {
      controller.abort()
      console.log('http call in verifyEmail aborted')
    }
  }
}

export function* verifySiret({ siret, callback }) {
  const controller = new AbortController()
  const { authTokenSession, lang } = yield select(selectAuthData)
  const { verify_siret_url: url } = yield select(selectPageConfigurationData)
  const fullUrl = `${url}?lang=${lang}&siret=${encodeURIComponent(siret)}`
  yield put(updateCustomerModuleConfiguration({ siretIsLoading: true }))
  try {
    const config = yield call(getAPIConfig, { method: 'get', authTokenSession })
    const signal = controller.signal
    const response = yield call(fetch, fullUrl, { ...config, signal })
    const result = yield call([response, response.json])
    yield put(updateCustomerModuleConfiguration({ siretIsLoading: false }))
    yield call(callback, result)
  } catch (error) {
    console.warn('verifySiretError', error)
  } finally {
    if (yield cancelled()) {
      controller.abort()
      console.log('http call in verifySiret aborted')
    }
  }
}

export function* registerToNewsletter({ options, callback }) {
  const controller = new AbortController()
  const { authTokenSession, lang } = yield select(selectAuthData)
  const { register_newsletter_url: url } = yield select(selectPageConfigurationData)

  try {
    const signal = controller.signal
    const config = yield call(getAPIConfig, { method: 'post', authTokenSession, body: options })
    const response = yield call(fetch, url + '?lang=' + lang, { ...config, signal })
    const result = yield call([response, response.json])

    yield call(callback, result, response.status)
  } catch (error) {
    console.warn('registerToNewsletterError', error)
  } finally {
    if (yield cancelled()) {
      controller.abort()
      console.log('http call in registerToNewsletter aborted')
    }
  }
}
