import PropTypes from 'prop-types'
import { FormControl } from 'baseui/form-control'
import { Checkbox, LABEL_PLACEMENT } from 'baseui/checkbox'
import PanelInfoBlock from '@/shared/components/Panel/bones/PanelInfoBlock'

import { useTranslation } from 'react-i18next'

const BacVoucher = ({ currentValue, handleValue, disabled }) => {
  const { t } = useTranslation()

  return (
    <>
      <FormControl>
        <Checkbox
          disabled={disabled}
          checked={currentValue}
          onChange={e => handleValue(e.target.checked)}
          labelPlacement={LABEL_PLACEMENT.left}
        >
          {t('subscriptionForm:customer_module.bac_voucher.label')}
        </Checkbox>
      </FormControl>
      {currentValue && (
        <PanelInfoBlock type={'warning'}>{t('subscriptionForm:customer_module.bac_voucher.warning')}</PanelInfoBlock>
      )}
    </>
  )
}

BacVoucher.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  handleValue: PropTypes.func,
  currentValue: PropTypes.bool,
  disabled: PropTypes.bool,
  isEditable: PropTypes.bool,
}

export default BacVoucher
