import { Route, Routes } from 'react-router-dom'
import EditForm from './SubscriptionForm/EditForm'

const SubscriptionForm = () => {
  return (
    <Routes>
      <Route path='/:id' element={<EditForm />} />
    </Routes>
  )
}

export default SubscriptionForm
