import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import useBusinessType from '@/shared/hooks/useBusinessType'
import { useParams } from 'react-router-dom'
import SubscriptionForm from '@/containers/SubscriptionFormPageNew/SubscriptionForm/SubscriptionForm'
import useHttp from '@/shared/hooks/useHttp'
import { selectAuthData } from '@/containers/Login/AuthSelectors'
import FormStatusError from '@/containers/SubscriptionFormPage/SubscriptionForm/FormStatusError'
import CommonFallback from '@/shared/components/CommonFallback'

const EditForm = () => {
  const [data, setData] = useState(null)
  const { loaderRoutes, loaderRoutesEnergy, loaderRoutesInsurance, authTokenSession, lang } =
    useSelector(selectAuthData)
  const [loadStatus, setLoadStatus] = useState(null)
  const { getApplicationUrl } = useBusinessType({
    loaderRoutesEnergy,
    loaderRoutes,
    loaderRoutesInsurance,
    modulesStatuses: {},
    modulesStatusesEnergy: {},
    modulesStatusesInsurance: {},
  })

  const initUrl = getApplicationUrl('telesales-form-new') ?? ''
  const { id } = useParams()
  const { sendRequest, getConfig } = useHttp()
  const initialize = () => {
    sendRequest(initUrl.replace('*', id) + '?isSpa=1&lang=' + (lang ?? 'fr'), getConfig('GET'), (json, statusCode) => {
      if (statusCode !== 200) {
        setLoadStatus(statusCode)
        return
      }
      setData(json)
    })
  }

  useEffect(() => {
    if (id) {
      initialize()
    }
  }, [])

  if (loadStatus) {
    return <FormStatusError statusCode={loadStatus} />
  }

  if (!data?.id || !data?.loadViewParametersRoute) {
    return <CommonFallback />
  }

  return (
    <SubscriptionForm
      id={data.id.toString()}
      country={data.country}
      saveRoute={data.saveRoute}
      token={authTokenSession}
      loadViewParametersRoute={data.loadViewParametersRoute}
    />
  )
}

export default EditForm
