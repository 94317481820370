const ApplicationCountry = ({ lang }) => {
  if (lang === 'fr') {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '10px',
          marginBottom: '2rem',
        }}
      >
        🇫🇷
      </div>
    )
  }
  if (lang === 'be') {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '10px',
          marginBottom: '2rem',
        }}
      >
        🇧🇪
      </div>
    )
  }

  if (lang === 'it') {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '10px',
          marginBottom: '2rem',
        }}
      >
        🇮🇹
      </div>
    )
  }

  return ''
}

export default ApplicationCountry
