import formatUTCDate from '@/shared/tools/formatUTCDate'

export const mapEnergySubscriptionData = viewParameters => {
  const currentContract = viewParameters?.current_subscriber ?? {}

  return {
    contractLoaded: true,
    elecMeter: mapElectricityData(viewParameters),
    gasMeter: mapGasData(viewParameters),
    characteristics: mapCharacteristicsData(currentContract),
    contract: mapContractData(currentContract),
    propane: mapPropaneData(viewParameters),
    consumption: mapConsumptionData(currentContract),
    estimation: mapEstimationData(currentContract),
  }
}

export const mapEstimationData = currentContract => {
  return {
    ...{
      housingType: null,
      occupancyFrequency: null,
      mainHome: null,
      numberDays: null,
      occupantsNumber: null,
      surface: null,
      recentInsulationWork: null,
      heatingMode: null,
      hotWaterMode: null,
      cookingMode: null,
      dishwasher: null,
      dryer: null,
      separateFreezer: null,
      heatPump: null,
      electricCar: null,
      electricCarDistance: null,
      airConditionersOneRoom: null,
      airConditionersMultipleRooms: null,
    },
    ...currentContract.product_energy?.estimation_form?.datas,
    ...(currentContract.product_energy?.estimation_form?.result && {
      result: currentContract.product_energy.estimation_form.result,
    }),
  }
}

export const mapConsumptionData = currentContract => {
  return {
    carBase: currentContract.product_energy?.car_elec_base,
    carBaseOriginal: currentContract.product_energy?.car_elec_base_original,
    carHp: currentContract.product_energy?.car_elec_hp,
    carHpOriginal: currentContract.product_energy?.car_elec_hp_original,
    carHc: currentContract.product_energy?.car_elec_hc,
    carHcOriginal: currentContract.product_energy?.car_elec_hc_original,
    carGas: currentContract.product_energy?.car_gas,
    carGasOriginal: currentContract.product_energy?.car_gas_original,
    consumptionBase: currentContract.product_energy?.italy_consumption?.base,
    consumptionBiorariaF1: currentContract.product_energy?.italy_consumption?.biorariaF1,
    consumptionBiorariaF2: currentContract.product_energy?.italy_consumption?.biorariaF2,
    consumptionBiorariaF3: currentContract.product_energy?.italy_consumption?.biorariaF3,
    surface: currentContract.product_energy?.surface ?? 0,
    heating: currentContract.product_energy?.heating_energy ?? '',
    hotwater: currentContract.product_energy?.hotwater_energy ?? '',
    cooking: currentContract.product_energy?.cooking_energy ?? '',
    monthlyAmount: currentContract.product_energy?.monthly_amount_calculated ?? '',
    modificationReason: currentContract.product_energy?.car_modification_reason ?? '',
  }
}

export const mapPropaneData = viewParameters => {
  const propaneInfo = viewParameters.propane_info ?? {}

  return {
    energySupplier: propaneInfo?.energy_supplier_id ?? '',
    car: propaneInfo?.car_propane ?? '',
    explanations: propaneInfo?.description ?? '',
    alreadyClient: propaneInfo?.already_client === 1 ? true : propaneInfo?.already_client === 0 ? false : '',
    quoteReason: propaneInfo?.quote_reason ?? '',
    garden: propaneInfo?.garden === '1' ? true : propaneInfo?.garden === '0' ? false : '',
    gardenArea: propaneInfo?.gardenArea ?? '',
    tankType: propaneInfo?.tankType ?? '',
    tankAccess: propaneInfo?.tankAccess ?? '',
    bottleNumber: propaneInfo?.bottleNumber ?? '',
    bottleSize: propaneInfo?.bottleSize ?? '',
    contactTime: propaneInfo?.contactTime ?? '',
    deliveryFrequency: propaneInfo?.deliveryFrequency ?? '',
    projectDeadline: propaneInfo?.projectDeadline ?? '',
    installationAge: propaneInfo?.installationAge ?? '',
    presence: propaneInfo?.presence ?? '',
    currentUse: propaneInfo?.currentUse ?? [],
    filling: propaneInfo?.filling ?? '',
  }
}

export const mapContractData = currentContract => {
  return {
    providerId: currentContract.product_energy?.organisation_id ?? '',
    offerId: currentContract.product_energy?.offer_id ?? '',
    providerExternalId: currentContract.product_energy?.provider_external_id ?? '',
    isDebordement: currentContract.is_debordement ?? '', // TO DO  : check if it's the right place to store it or in common data
    mesEmergency: currentContract.product_energy?.mes_emergency ?? '',
    commitmentEndDate: currentContract.product_energy?.commitment_end_date ?? null,
  }
}

export const mapCharacteristicsData = currentContract => {
  return {
    energyType: currentContract.product_energy?.energy_type ?? '',
    prestationType: currentContract.product_energy?.prestation_type ?? '',
    contractType: currentContract.product_energy?.contract_type ?? '',
    cdfDelayed: currentContract.product_energy?.cdf_delayed ?? 0,
    disclaimerCommitmentPro: currentContract.product_energy?.disclaimer_commitment_pro ?? null,
  }
}

export const mapElectricityData = viewParameters => {
  const elecInfo = viewParameters.elec_info ?? {}

  return {
    pdlNo: elecInfo?.meter_id ?? '',
    meterState: elecInfo?.meter_state ?? '',
    tariffOption: elecInfo?.meter_option ?? '',
    meterPower: elecInfo?.meter_power ?? '',
    energyOrigin: elecInfo?.energy_origin ?? '',
    effectiveDate: elecInfo?.effective_date ?? '',
    energySupplier: elecInfo?.energy_supplier_id ?? '',
    enedisAuth: (elecInfo?.meter_consent_acceptation ?? null) !== null,
    consentDate: elecInfo?.meter_consent_acceptation
      ? formatUTCDate(new Date(elecInfo?.meter_consent_acceptation))
      : null,
    indexBaseHP: elecInfo?.main_index ?? '',
    indexHC: elecInfo?.secondary_index ?? '',
    originalTariffOption: elecInfo?.original_meter_option ?? '',
    originalMeterPower: elecInfo?.original_meter_power ?? '',
    retractionDelayBypass: elecInfo?.bypass_retractation === 1,
    meterType: elecInfo?.meter_type ?? '',
    meterOpening: elecInfo?.meter_opening ?? '',
    readingDate: elecInfo?.reading_date ?? '',
    billAmount: elecInfo?.last_bill_amount ?? '',
    smartMeter: elecInfo?.is_smart_meter ?? '',
  }
}

export const mapGasData = viewParameters => {
  const gasInfo = viewParameters.gas_info ?? {}

  return {
    energyOrigin: gasInfo?.energy_origin ?? '',
    meterAuth: (gasInfo?.meter_consent_acceptation ?? null) !== null,
    consentDate: gasInfo?.meter_consent_acceptation
      ? formatUTCDate(new Date(gasInfo?.meter_consent_acceptation))
      : null,
    pceNo: gasInfo?.meter_id ?? '',
    index: gasInfo?.main_index ?? '',
    readingDate: gasInfo?.reading_date ?? '',
    effectiveDate: gasInfo?.effective_date ?? '',
    retractionDelayBypass: gasInfo?.bypass_retractation === 1,
    meterType: gasInfo?.meter_type ?? '',
    billAmount: gasInfo?.last_bill_amount ?? '',
    smartMeter: gasInfo?.is_smart_meter ?? '',
    energySupplier: gasInfo?.energy_supplier_id ?? '',
  }
}
