const selectraThemePrimitives = {
  primaryFontFamily: 'system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif',

  primary: '#1460AA',
  primary50: '#E8EFF7',
  primary100: '#E0FFFF',
  primary150: '#C7FFFF',
  primary200: '#ADF9FF',
  primary250: '#93DFFF',
  primary300: '#7AC6FF',
  primary350: '#61ADF7',
  primary400: '#015AA3',
  primary450: '#2E7AC4',
  primary500: '#1460AA',
  primary550: '#004791',
  primary600: '#002D77',
  primary650: '#00145E',
  primary700: '#000044',
  primary750: '#00002B',
  primary800: '#000011',

  accent: '#D97F18',
  accent50: '#FBF2E8',
  accent100: '#FFFFE4',
  accent150: '#FFFFCB',
  accent200: '#FFFFB1',
  accent250: '#FFFF98',
  accent300: '#FFE57E',
  accent350: '#FFCC65',
  accent400: '#FFB24B',
  accent450: '#F39932',
  accent500: '#D97F18',
  accent550: '#C06600',
  accent600: '#A64C00',
  accent650: '#8D3300',
  accent700: '#731900',
  accent750: '#5A0000',
  accent800: '#400000',

  negative: '#DC0909',
  negative50: '#FFEEEE',
  negative100: '#FEE9E9',
  negative150: '#FFD5D5',
  negative200: '#FFBBBB',
  negative250: '#FFA2A2',
  negative300: '#FF8888',
  negative350: '#FF6F6F',
  negative400: '#FF5555',
  negative450: '#FF3C3C',
  negative500: '#F52222',
  negative550: '#DC0909',
  negative600: '#C20000',
  negative650: '#A90000',
  negative700: '#8F0000',
  negative750: '#760000',
  negative800: '#5C0000',

  warning: '#C45405',
  warning50: '#FCF0E9',
  warning100: '#FFFFEA',
  warning150: '#FFEBC3',
  warning200: '#FFFFB7',
  warning250: '#FFED9E',
  warning300: '#D2A604',
  warning350: '#FFBA6B',
  warning400: '#FFA051',
  warning450: '#F78738',
  warning500: '#DD6D1E',
  warning550: '#C45405',
  warning600: '#AA3A00',
  warning650: '#912100',
  warning700: '#770700',
  warning750: '#5E0000',
  warning800: '#440000',

  positive: '#0C8A1E',
  positive50: '#E9F6EB',
  positive100: '#F1FFFF',
  positive150: '#D8FFEA',
  positive200: '#BEFFD0',
  positive250: '#A5FFB7',
  positive300: '#8BFF9D',
  positive350: '#72F084',
  positive400: '#58D66A',
  positive450: '#3FBD51',
  positive500: '#25A337',
  positive550: '#0C8A1E',
  positive600: '#007004',
  positive700: '#003D00',
  positive750: '#002400',
  positive800: '#000A00',

  white: '#FFFFFF',
  mono100: '#DEE8F2',
  mono200: '#C1D1E2',
  mono300: '#A8BACD',
  mono400: '#93A3B4',
  mono500: '#828C97',
  mono600: '#6B7580',
  mono700: '#565E67',
  mono800: '#41474E',
  mono900: '#2C3135',
  mono1000: '#171A1C',
  black: '#0C0D0E',

  grey: '#58595B',
  bg050: '#E9EEFB',
  bg100: '#D3E6F8',
  bg150: '#BDD9F4',
}

export default selectraThemePrimitives
