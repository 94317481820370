import Customer from '../../Components/Customer/Customer'
import ZohoId from '../../Components/ZohoId/ZohoId'

const Client = () => {
  return (
    <div style={{ width: '100%' }}>
      <ZohoId />
      <Customer />
    </div>
  )
}

export default Client
