import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import useFormStatus from '@/containers/SubscriptionFormPageNew/SubscriptionForm/useFormStatus'
import { subscriptionFormActionsV2 } from '@/containers/SubscriptionFormPageNew/SubscriptionForm/SubscriptionFormV2Sagas'
import { selectCustomerSubscriptionData } from '@/containers/SubscriptionFormPageNew/SubscriptionForm/SubscriptionFormSelectors'
import { updateCustomerData } from '@/containers/SubscriptionFormPageNew/SubscriptionForm/SubscriptionFormSlice'
import { selectPageConfigurationData } from '@/store/PageConfiguration/PageConfigurationSelectors'
import { selectZohoModuleConfigurationData } from '@/containers/SubscriptionFormPageNew/SubscriptionForm/SubscriptionFormConfigurationSelectors'
import isMandatory from '@/containers/SubscriptionFormPage/isMandatory'
import getError from '@/containers/SubscriptionFormPage/getError'

const useZoho = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { zohoId } = useSelector(selectCustomerSubscriptionData)
  const { zoho_dossier_url: zohoDossierUrl } = useSelector(selectPageConfigurationData)
  const { isMcpClient } = useSelector(selectZohoModuleConfigurationData)
  const [zohoIdModified, setZohoIdModified] = useState(false)
  const [error, setError] = useState('')
  const [newZoho, setNewZoho] = useState('')
  const [showNewZohoField, setShowNewZohoField] = useState(false)
  const { formIsLoaded, LoadingPanelNew, isEditable, mandatoryFields, errors } = useFormStatus()
  const errorText = getError('zoho_id', errors)
  const mandatory = isMandatory('zohoId', mandatoryFields)
  const customerZohoIdUpdate = value => {
    dispatch(updateCustomerData({ zohoId: value }))
  }
  const validateZohoId = async value => {
    dispatch({
      type: subscriptionFormActionsV2.ZOHO_MODULE_VERIFY_ID_V2,
      zohoId: value,
    })
  }
  const onZohoIdBlurHandler = async event => {
    validateZohoId(event.target.value)
  }
  const updateZohoIdCallback = json => {
    if (json.success) {
      customerZohoIdUpdate(json.new_zoho_id)
      setShowNewZohoField(false)
      setError('')
      setNewZoho('')
    }
    if (!json.success && json.error) {
      setError(json.error)
    }
  }
  const updateZoho = async () => {
    setError('')
    dispatch({
      type: subscriptionFormActionsV2.ZOHO_MODULE_UPDATE_ID_V2,
      zohoId: newZoho,
      updateZohoIdCallback,
    })
  }

  useEffect(() => {
    if (formIsLoaded && !zohoIdModified && zohoId) {
      setZohoIdModified(true)
      validateZohoId(zohoId)
    }
  }, [zohoIdModified, formIsLoaded, zohoId])

  return {
    formIsLoaded,
    LoadingPanelNew,
    errorText,
    mandatory,
    onZohoIdBlurHandler,
    customerZohoIdUpdate,
    isEditable,
    t,
    updateZoho,
    error,
    newZoho,
    showNewZohoField,
    setShowNewZohoField,
    setNewZoho,
    zohoId,
    zohoDossierUrl,
    isMcpClient,
  }
}

export default useZoho
