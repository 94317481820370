import { styled } from 'baseui'

export const StyledPanel = styled('div', ({ $theme, $tool }) => ({
  padding: '1rem',
  backgroundColor: $tool ? $theme.colors.bg050 : 'white',
  margin: '0 .5rem',
  borderRadius: '1rem',
  boxShadow: $theme.lighting.shadow400,
  overflow: 'hidden',
}))

export const StyledCardDragger = styled('div', () => ({
  zIndex: 10,
  position: 'absolute',
  top: 0,
  right: 0,
  width: '3rem',
  height: '3rem',
}))

export const StyledHeader = styled('div', () => ({
  display: 'inline-flex',
  alignItems: 'center',
  width: '100%',
}))

export const StyledIcon = styled('img', () => ({
  width: '1.25rem',
  height: '1.25rem',
  marginRight: '.5rem',
}))

export const StyledTitle = styled('h3', ({ $theme }) => ({
  flexGrow: 1,
  margin: 0,
  color: $theme.colors.primary,
  ...$theme.typography.HeadingXSmall,
  fontSize: '1rem',
}))

export const StyledNotification = styled('div', ({ $theme, $type, $boldText }) => {
  const outputColour = {}

  switch ($type) {
    case 'negative':
      outputColour.text = $theme.colors.negative
      outputColour.bg = $theme.colors.negative150
      outputColour.shadow = $theme.colors.negative400
      break
    case 'positive':
      outputColour.text = $theme.colors.positive
      outputColour.bg = $theme.colors.positive150
      outputColour.shadow = $theme.colors.positive400
      break
    case 'warning':
    case 'additionalSale':
      outputColour.text = $theme.colors.grey
      outputColour.bg = $theme.colors.warning150
      outputColour.shadow = $theme.colors.warning300
      break
    case 'script':
    case 'agentScript':
    default:
      outputColour.text = $theme.colors.grey
      outputColour.bg = $theme.colors.bg050
      outputColour.shadow = $theme.colors.primary400
      break
  }

  return {
    display: 'flex',
    padding: '8px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    alignSelf: 'stretch',
    borderRadius: '8px',
    border: `1px dashed ${outputColour.shadow}`,
    backgroundColor: outputColour.bg,
    color: outputColour.text,
    ...$theme.typography.ParagraphXSmall,
    fontWeight: $boldText ? 'bold' : 'normal',
    fontSize: $boldText ? '1rem' : '0.875rem',
  }
})

export const StyledNotificationWrapper = styled('div', () => ({
  display: 'flex',
  alignItems: 'flex-start',
  gap: '8px',
  alignSelf: 'stretch',
}))
