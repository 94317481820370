import { call, put, select } from 'redux-saga/effects'
import { selectAuthData } from '@/containers/Login/AuthSelectors'
import { selectPageConfigurationData } from '@/store/PageConfiguration/PageConfigurationSelectors'
import getAPIConfig from '@/shared/tools/getAPIConfig'
import {
  updateElectricityMeterModuleConfiguration
} from '@/containers/SubscriptionFormPage/SubscriptionFormConfigurationSlice'

export const pdlModuleActions = {
  METER_MODULE_CHECK_DUPLICATED_PDL: 'METER_MODULE_CHECK_DUPLICATED_PDL',
  METER_MODULE_REQUEST_PDL_DATA: 'METER_MODULE_REQUEST_PDL_DATA',
  METER_MODULE_VALIDATE_PDL_METER_SYNTAX: 'METER_MODULE_VALIDATE_PDL_METER_SYNTAX',
  RETRIEVE_PDL_CACHE_DATA: 'RETRIEVE_PDL_CACHE_DATA',
}

export function* checkDuplicatedPdlMeter({ meterId, energyType, duplicatedCallback }) {
  const { authTokenSession, lang } = yield select(selectAuthData)
  const { pod_validate_duplicated_url: url } = yield select(selectPageConfigurationData)
  const fullUrl = `${url}?lang=${lang}&meterId=${meterId}&energyType=${energyType}`
  try {
    const config = yield call(getAPIConfig, { method: 'get', authTokenSession })
    const response = yield call(fetch, fullUrl, config)
    const result = yield call([response, response.json])
    if (response.status === 200) {
      yield call(duplicatedCallback, result)
    } else {
      console.error('checkDuplicatedMeterError', result, response.status)
      yield call(duplicatedCallback, null)
    }
  } catch (error) {
    console.warn('checkDuplicatedMeterError', error)
  }
}

export function* requestPdlMeterData({ body }) {
  const { authTokenSession, lang } = yield select(selectAuthData)
  const { pod_eligibility_url: url } = yield select(selectPageConfigurationData)
  try {
    yield put(
      updateElectricityMeterModuleConfiguration({
        pdlEligibilityLoading: true,
        pdlEligibilityData: {},
      }),
    )
    const config = yield call(getAPIConfig, { method: 'post', authTokenSession, body })
    const response = yield call(fetch, url + '?lang=' + lang, config)
    yield call([response, response.json])
  } catch (error) {
    console.warn('requestMeterDataError', error)
  }
}

export function* validatePdlMeterSyntax({ meterId, callback }) {
  const { authTokenSession, lang } = yield select(selectAuthData)
  const { pod_validate_url: url } = yield select(selectPageConfigurationData)
  const fullUrl = `${url}?lang=${lang}&meterId=${meterId}`
  try {
    const config = yield call(getAPIConfig, { method: 'get', authTokenSession })
    const response = yield call(fetch, fullUrl, config)
    const result = yield call([response, response.json])
    if (response.status === 200) {
      yield call(callback, result)
    } else {
      console.error('validateMeterSyntaxError', result, response.status)
    }
  } catch (error) {
    console.warn('validateMeterSyntaxError', error)
  }
}
export function* retrievePdlCacheDataForKey({ key, route, subscriptionId, callback }) {
  const { authTokenSession, lang } = yield select(selectAuthData)
  try {
    const config = yield call(getAPIConfig, { method: 'get', authTokenSession })
    const response = yield call(fetch, route + '/' + key + '/' + subscriptionId + '?lang=' + lang, config)
    const responseData = yield call([response, response.json])
    yield call(callback, responseData, response.status)
  } catch (error) {
    console.warn('retrieveCacheDataError', error)
  }
}
