import { defaultValues } from '../../../SubscriptionForm/Energy/defaultValues'

export const mapProviderConfigData = (apiResponse) => {
  return {
    allowCdfOnMes: apiResponse.allow_cdf_on_mes ?? 0,
    allowDisclaimerCommitmentPro: apiResponse.allow_disclaimer_commitment_pro ?? 0,
    isIntegrated: apiResponse.is_integrated ?? false,
    providerWorkflow: apiResponse.workflow?.name ?? '',
    debordementCallStatus: apiResponse.debordement_call_status ?? '',
    hasMonthlyAmountEndpoint: apiResponse.monthly_amount_service ?? false,
    monthlyAmountEndpointName: apiResponse.workflow?.apiName ?? '',
    debordementCallLeadSource: apiResponse.debordement_call_lead_source ?? '',
    mesEmergencyExpress: apiResponse.mes_emergency_express ?? false,
    mesEmergencyUrgent: apiResponse.mes_emergency_urgent ?? false,
    allowConsumptionHistoricOnMes: apiResponse.allow_consumption_historic_on_mes ?? false,
    name: apiResponse.name ?? '',
    canChangeMeterPower: apiResponse.can_change_meter_power ?? false,
    canChangeMeterOption: apiResponse.can_change_meter_option ?? false,
    useCarRangeRule: apiResponse.car_range_rule_active ?? false,
    minimumCarElectricity: apiResponse.car_electricity_minimum ?? defaultValues.carElectricity,
    minimunCarGas: apiResponse.car_gas_main_minimum ?? defaultValues.carGasMain,
    minimunCarGasSecondary: apiResponse.car_gas_secondary_minimum ?? defaultValues.carGasSecondary,
    carLimitPercentMes: apiResponse.energy_car_limit_mes_percent ?? 0,
    carLimitPercentMesStatus: apiResponse.energy_car_limit_mes_status ?? false,
    saleIdMandatory: apiResponse.sale_id_mandatory ?? 0,
    indexMandatoryNonLinkyMeter: apiResponse.energy_index_mandatory_non_communicant_meters ?? 0,
    bicMandatory: apiResponse.bic_mandatory ?? '',
    carMandatoryOnRealPayment: apiResponse.real_payment_car_mandatory ?? 0,
    promoCodes: apiResponse.promo_codes ?? false,
    promoDescription: apiResponse.promo_description ?? ''
  }
}
