import { useTranslation } from 'react-i18next'
import useFormStatus from '@/containers/SubscriptionFormPageNew/SubscriptionForm/useFormStatus'
import { useDispatch, useSelector } from 'react-redux'
import { selectCustomerSubscriptionData } from '@/containers/SubscriptionFormPageNew/SubscriptionForm/SubscriptionFormSelectors'
import { updateCustomerData } from '@/containers/SubscriptionFormPageNew/SubscriptionForm/SubscriptionFormSlice'
import { selectPageConfigurationData } from '@/store/PageConfiguration/PageConfigurationSelectors'
import { subscriptionFormActionsV2 } from '@/containers/SubscriptionFormPageNew/SubscriptionForm/SubscriptionFormV2Sagas'
import { selectCustomerModuleConfigurationData } from '@/containers/SubscriptionFormPageNew/SubscriptionForm/SubscriptionFormConfigurationSelectors'
import {
  addCommonFormErrors,
  deleteCommonFormErrors,
  updateCustomerModuleConfiguration,
} from '@/containers/SubscriptionFormPageNew/SubscriptionForm/FormConfigurationV2Slice'
import getError from '@/containers/SubscriptionFormPage/getError'
import { selectAuthData } from '@/containers/Login/AuthSelectors'

const useCustomer = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { lang } = useSelector(selectAuthData)
  const {
    dataCollectionAuth,
    name,
    name2,
    surname,
    surname2,
    secondName,
    civility,
    civility2,
    pro_social_reason: proSocialReason,
    phone2,
    phone1,
    referralFirstName,
    referralSecondName,
    isNewsletterRegistered,
    bacVoucher,
    email,
    clientType,
    pro_siret: proSiret,
    tax_id_code: taxIdCode,
  } = useSelector(selectCustomerSubscriptionData)

  const {
    civility_list: civilityList,
    client_type_list: clientTypeOptions,
    activities_list: activitiesList,
  } = useSelector(selectPageConfigurationData)
  const { emailIsLoading, prefilledEmail } = useSelector(selectCustomerModuleConfigurationData)
  const { formIsLoaded, LoadingPanelNew, zohoIsInValid, isEditable, mandatoryFields, errors } = useFormStatus()

  const customerUpdate = payload => {
    dispatch(updateCustomerData(payload))
  }
  const emailValidationHandler = response => {
    removeErrors('email')
    if (response.error) {
      setErrors({ email: [response.errorMessage] })
      customerUpdate({ neverbounce_result: false })
      return
    }

    if (!response.valid) {
      setErrors({ email: [t('subscriptionForm:customer_module.email.error')] })
      customerUpdate({ neverbounce_result: false })
      return
    }

    if (response.valid) {
      customerUpdate({ neverbounce_result: 'valid' })
    }
  }
  const verifyEmail = event => {
    dispatch({
      type: subscriptionFormActionsV2.CUSTOMER_MODULE_VERIFY_EMAIL_V2,
      email: event.target.value,
      callback: emailValidationHandler,
    })
  }
  const setErrors = error => {
    dispatch(addCommonFormErrors(error))
  }
  const removeErrors = error => {
    dispatch(deleteCommonFormErrors(error))
  }
  const captionForceEmail = boolean => {
    dispatch(
      updateCustomerModuleConfiguration({
        prefilledEmail: {
          status: boolean,
          message: boolean ? t('subscriptionForm:customer_module.email.forcedCaption') : '',
        },
      }),
    )
  }

  const authorizeDataCollectionHandler = value => {
    if (value === true) {
      customerUpdate({ dataCollectionAuth: value })
      return
    }
    // reset data if uncheck
    customerUpdate({
      dataCollectionAuth: value,
      name: '',
      surname: '',
      phone1: '',
      civility: '',
    })
  }

  return {
    formIsLoaded,
    LoadingPanelNew,
    zohoIsInValid,
    customerUpdate,
    isEditable,
    t,
    verifyEmail,
    setErrors,
    removeErrors,
    captionForceEmail,
    authorizeDataCollectionHandler,
    emailError: getError('email', errors),
    errors,
    emailIsLoading,
    mandatoryFields,
    dataCollectionAuth,
    name,
    name2,
    surname,
    surname2,
    secondName,
    civility,
    civility2,
    phone1,
    phone2,
    proSocialReason,
    referralFirstName,
    referralSecondName,
    isNewsletterRegistered,
    bacVoucher,
    email,
    civilityList,
    prefilledEmail,
    lang,
    proSiret,
    taxIdCode,
    activitiesList,
    clientTypeOptions,
    clientType,
  }
}

export default useCustomer
