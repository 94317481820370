export default function inRangeCARMes(original, modified, mesPercentRuleStatus, ratio, prestationType) {
  if (
    !prestationType ||
    prestationType === 'cdf' ||
    original === 0 ||
    ratio === 0 ||
    mesPercentRuleStatus === false ||
    mesPercentRuleStatus === '0' ||
    mesPercentRuleStatus === 0 ||
    isNaN(original)
  ) {
    return true
  }

  const minAllowed = (original * ratio) / 100

  return parseInt(modified) >= parseInt(minAllowed)
}
