import PropTypes from 'prop-types'
import { FormControl } from 'baseui/form-control'
import { SIZE } from 'baseui/input'
import SDButtonGroup from '@/shared/components/SDComponents/SDButtonGroup/SDButtonGroup'
import SDButtonGroupButton from '@/shared/components/SDComponents/SDButtonGroup/SDButtonGroupButton'
import { useTranslation } from 'react-i18next'

const SecondName = ({ children, currentValue, onClickHandler, disabled }) => {
  const { t } = useTranslation()
  const selected = currentValue ? [0] : []

  return (
    <>
      <FormControl>
        <SDButtonGroup
          disabled={disabled}
          mode={'checkbox'}
          size={SIZE.compact}
          selected={selected}
          onClick={(event, index) => {
            event.preventDefault()
            if (!selected.includes(index)) {
              onClickHandler(true)
            } else {
              onClickHandler(false)
            }
          }}
        >
          <SDButtonGroupButton>{t('subscriptionForm:customer_module.secondName.label')}</SDButtonGroupButton>
        </SDButtonGroup>
      </FormControl>
      {currentValue && <>{children}</>}
    </>
  )
}

SecondName.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  onClickHandler: PropTypes.func,
  currentValue: PropTypes.bool,
  disabled: PropTypes.bool,
}

export default SecondName
