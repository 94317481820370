import PropTypes from 'prop-types'

import { useStyletron } from 'baseui'

import { StyledNotification, StyledNotificationWrapper } from './styled-components'
import IconCarbonMisuseOutline from '@/shared/icons/IconCarbonMisuseOutline'
import IconCarbonWarningAlt from '@/shared/icons/IconCarbonWarningAlt'
import IconCarbonCheckmarkOutline from '@/shared/icons/IconCarbonCheckmarkOutline'
import IconCarbonVoiceActivate from '@/shared/icons/IconCarbonVoiceActivate'
import IconCarbonInformation from '@/shared/icons/IconCarbonInformation'
import IconCarbonRocket from '@/shared/icons/IconCarbonRocket'
import IconCustomAgentScript from '@/shared/icons/IconCustomAgentScript'

const HOPNotification = ({ children, icon, type, boldText = false, hidden, primaryText }) => {
  const [, theme] = useStyletron()
  const iconProps = {
    size: 24,
    color:
      type === 'negative' || type === 'warning' || type === 'positive'
        ? theme.colors[type]
        : type === 'additionalSale'
          ? theme.colors.warning
          : theme.colors.primary,
  }
  const getIcon = () => {
    switch (type) {
      case 'negative':
        return <IconCarbonMisuseOutline {...iconProps} />
      case 'warning':
        return <IconCarbonWarningAlt {...iconProps} />
      case 'positive':
        return <IconCarbonCheckmarkOutline {...iconProps} />
      case 'script':
        return <IconCarbonVoiceActivate {...iconProps} />
      case 'agentScript':
        return <IconCustomAgentScript {...iconProps} />
      case 'additionalSale':
        return <IconCarbonRocket {...iconProps} />
      default:
        return <IconCarbonInformation {...iconProps} />
    }
  }
  const outputIcon = (() => {
    if (icon !== false) {
      if (typeof icon === 'function') {
        return icon(iconProps)
      }
      return getIcon()
    }
    return ''
  })()

  if (hidden) {
    return null
  }

  return (
    <StyledNotification $type={type} $boldText={boldText}>
      <StyledNotificationWrapper>
        {outputIcon}{' '}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start',
            gap: '4px',
            flex: '1 0 0',
            alignSelf: 'stretch',
          }}
        >
          {primaryText}
        </div>
      </StyledNotificationWrapper>
      {children}
    </StyledNotification>
  )
}

HOPNotification.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  icon: PropTypes.oneOfType([PropTypes.bool, PropTypes.func, PropTypes.object]),
  type: PropTypes.oneOf(['', 'additionalSale', 'default', 'negative', 'positive', 'script', 'warning', 'agentScript']),
  boldText: PropTypes.bool,
  hidden: PropTypes.bool,
  primaryText: PropTypes.string,
}

export default HOPNotification
