import { FormControl } from 'baseui/form-control'
import { Input, SIZE } from 'baseui/input'
import SDButton from '@/shared/components/SDComponents/SDButton/SDButton'
import useZoho from './useZoho'

const ZohoUpdater = () => {
  const { isEditable, t, updateZoho, error, newZoho, showNewZohoField, setShowNewZohoField, setNewZoho } = useZoho()

  if (isEditable !== false) {
    return ''
  }

  return (
    <>
      {t('subscriptionForm:zoho_module.script_new_zoho')}
      {showNewZohoField === false && (
        <SDButton
          block={true}
          onClick={event => {
            event.preventDefault()
            setShowNewZohoField(!showNewZohoField)
          }}
        >
          {t('subscriptionForm:zoho_module.label_update_zoho')}
        </SDButton>
      )}
      {showNewZohoField === true && (
        <>
          <FormControl label={t('subscriptionForm:zoho_module.label_update_zoho')} error={error}>
            <Input
              size={SIZE.compact}
              placeholder={t('subscriptionForm:zoho_module.label_update_zoho')}
              value={newZoho}
              onChange={event => setNewZoho(event.target.value)}
              error={error !== ''}
              autoComplete={'off'}
            />
          </FormControl>
          <SDButton block={true} onClick={updateZoho}>
            {t('subscriptionForm:zoho_module.button_save')}
          </SDButton>
        </>
      )}
    </>
  )
}

export default ZohoUpdater
