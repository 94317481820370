import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  common: {
    formLoaded: false,
    formCanBeSubmitted: true,
    formSubmitted: false,
    isEditable: true,
    canSendSubscription: false,
    showSaveNotification: false,
    isRetracted: false,
    saveAction: '',
    subscriptionStatus: '',
    lastSentDate: '',
    realStatus: '',
    id: '',
    token: '',
    saveUrl: '',
    statusErrors: [],
    errors: {},
    mandatoryFields: {},
    missingFieldsDetails: {},
    createdByUserId: '',
  },
  zohoModule: {
    isValidZohoId: false,
    isMcpClient: false,
    isMcpLocked: false,
  },
  customerModule: {
    newsletterData: {},
    newsletterLoading: false,
    emailIsLoading: false,
    prefilledEmail: {
      status: false,
      message: '',
    },
    siretIsLoading: false,
    siretIsValid: false,
  },
  characteristicsModule: {
    addressLoading: false,
    addressList: [],
    cityEligibility: {},
    cityEligibilityIsLoading: false,
  },
  bankingModule: {
    isBicEditable: true,
    ibanValidationData: {},
    hasPromoCodeFromProvider: false,
  },
  electricityMeterModule: {
    isValidPdl: false,
    pdlEligibilityPending: false,
    pdlEligibilityLoading: false,
    pdlEligibilityData: {},
    triggerSearchPod: false,
    searchPodResponses: null,
    oldOccupant: '',
  },
  gasMeterModule: {
    pceEligibilityLoading: false,
    pceEligibilityPending: false,
    isValidPce: false,
    pceEligibilityData: {},
  },
  providerConfig: {
    allowCdfOnMes: 0,
    allowDisclaimerCommitmentPro: 0,
    isIntegrated: false,
    providerWorkflow: '',
    debordementCallStatus: '',
    debordementCallLeadSource: '',
    mesEmergencyExpress: false,
    mesEmergencyUrgent: false,
  },
  offerConfig: {},
  offerModule: {
    offerIsAvailable: true,
    offerLoading: false,
    providerLoading: false,
    offers: {},
    providers: {},
    providerOptions: {},
    energyOrigin: { elec: [], gas: [] },
  },
  consumptionModule: {
    offerPriceLoading: false,
    prefilled: {
      carBase: {
        status: false,
        message: '',
      },
      carGas: {
        status: false,
        message: '',
      },
      carHp: {
        status: false,
        message: '',
      },
      carHc: {
        status: false,
        message: '',
      },
    },
  },
  propaneModule: {
    energySuppliers: {},
    quoteReasonsList: {},
    propaneVisibility: {},
  },
  additionalSales: {
    partnerCallbackTries: 0,
    tabToShow: 'assistanceOptions',
    shouldScroll: false,
  },
  pdlSearchData: {
    address: {},
    searchPodResponses: [],
    searchPodResponsesOriginal: [],
    viewPage: 'search',
    occupant: '',
    serialNumber: '',
    complement: '',
    building: '',
    locality: '',
    contractType: 'part',
    registrationNumber: '',
    pdlSelected: '',
    isOpen: false,
    isLoading: false,
    page: 1,
  },
  pceSearchData: {
    address: {},
    searchPodResponses: [],
    searchPodResponsesOriginal: [],
    viewPage: 'search',
    occupant: '',
    serialNumber: '',
    complement: '',
    pdlSelected: '',
    isOpen: false,
    isLoading: false,
    page: 1,
  },
}

const initialFormConfigurationUpdateStateHandler = (state, action) => {
  state.common = {
    ...initialState.common,
    formLoaded: true,
    ...action.payload,
  }
  state.zohoModule = { ...initialState.zohoModule }
  state.customerModule = { ...initialState.customerModule }
  state.characteristicsModule = { ...initialState.characteristicsModule }
  state.bankingModule = { ...initialState.bankingModule }
  state.electricityMeterModule = { ...initialState.electricityMeterModule }
  state.gasMeterModule = { ...initialState.gasMeterModule }
  state.providerConfig = { ...initialState.providerConfig }
  state.offerConfig = { ...initialState.offerConfig }
  state.offerModule = { ...initialState.offerModule }
  state.consumptionModule = { ...initialState.consumptionModule }
  state.propaneModule = { ...initialState.propaneModule }
  state.additionalSales = { ...initialState.additionalSales }
  state.pdlSearchData = { ...initialState.pdlSearchData }
  state.pceSearchData = { ...initialState.pceSearchData }
}

const addCommonFormErrorsHandler = (state, action) => {
  state.common = {
    ...state.common,
    errors: {
      ...state.common.errors,
      ...action.payload,
    },
  }
}

const deleteCommonFormErrorsHandler = (state, action) => {
  const newState = { ...state.common.errors }
  delete newState[action.payload]
  state.common.errors = newState
}

const resetCommonFormErrorsHandler = (state, action) => {
  state.common.errors = {}
}

const updateCommonConfigurationHandler = (state, action) => {
  state.common = {
    ...state.common,
    ...action.payload,
  }
}

const updateZohoModuleConfigurationHandler = (state, action) => {
  state.zohoModule = {
    ...state.zohoModule,
    ...action.payload,
  }
}

const updateCustomerModuleConfigurationHandler = (state, action) => {
  state.customerModule = {
    ...state.customerModule,
    ...action.payload,
  }
}

const updateCharacteristicsModuleConfigurationHandler = (state, action) => {
  state.characteristicsModule = {
    ...state.characteristicsModule,
    ...action.payload,
  }
}

const updateConsumptionModuleConfigurationHandler = (state, action) => {
  state.consumptionModule = {
    ...state.consumptionModule,
    ...action.payload,
  }
}

const updateOfferModuleConfigurationHandler = (state, action) => {
  state.offerModule = {
    ...state.offerModule,
    ...action.payload,
  }
}

const updateElectricityMeterModuleConfigurationHandler = (state, action) => {
  state.electricityMeterModule = {
    ...state.electricityMeterModule,
    ...action.payload,
  }
}

const updateGasMeterModuleConfigurationHandler = (state, action) => {
  state.gasMeterModule = {
    ...state.gasMeterModule,
    ...action.payload,
  }
}

const updateProviderConfigurationHandler = (state, action) => {
  state.providerConfig = {
    ...state.providerConfig,
    ...action.payload,
  }
}

const updateOfferConfigurationHandler = (state, action) => {
  state.offerConfig = {
    ...state.offerConfig,
    ...action.payload,
  }
}

const updateBankingsModuleConfigurationHandler = (state, action) => {
  state.bankingModule = {
    ...state.bankingModule,
    ...action.payload,
  }
}

const updatePropaneModuleConfigurationHandler = (state, action) => {
  state.propaneModule = {
    ...state.propaneModule,
    ...action.payload,
  }
}

const updateAdditionalSalesModuleConfigurationHandler = (state, action) => {
  state.additionalSales = {
    ...state.additionalSales,
    ...action.payload,
  }
}

const updatePdlSearchDataModuleConfigurationHandler = (state, action) => {
  state.pdlSearchData = {
    ...state.pdlSearchData,
    ...action.payload,
  }
}

const updatePceSearchDataModuleConfigurationHandler = (state, action) => {
  state.pceSearchData = {
    ...state.pceSearchData,
    ...action.payload,
  }
}

export const SubscriptionFormConfigurationSlice = createSlice({
  name: 'subscriptionFormConfiguration',
  initialState,
  reducers: {
    initialFormConfigurationStateUpdate: initialFormConfigurationUpdateStateHandler,
    updateCommonConfiguration: updateCommonConfigurationHandler,
    updateCustomerModuleConfiguration: updateCustomerModuleConfigurationHandler,
    updateCharacteristicsModuleConfiguration: updateCharacteristicsModuleConfigurationHandler,
    updateBankingsModuleConfiguration: updateBankingsModuleConfigurationHandler,
    updateZohoModuleConfiguration: updateZohoModuleConfigurationHandler,
    updateProviderConfiguration: updateProviderConfigurationHandler,
    updateOfferConfiguration: updateOfferConfigurationHandler,
    updateOfferModuleConfiguration: updateOfferModuleConfigurationHandler,
    addCommonFormErrors: addCommonFormErrorsHandler,
    deleteCommonFormErrors: deleteCommonFormErrorsHandler,
    resetCommonFormErrors: resetCommonFormErrorsHandler,
    updateElectricityMeterModuleConfiguration: updateElectricityMeterModuleConfigurationHandler,
    updateAdditionalSalesModuleConfiguration: updateAdditionalSalesModuleConfigurationHandler,
    updateConsumptionModuleConfiguration: updateConsumptionModuleConfigurationHandler,
    updatePropaneModuleConfiguration: updatePropaneModuleConfigurationHandler,
    updateGasMeterModuleConfiguration: updateGasMeterModuleConfigurationHandler,
    updatePdlSearchDataModuleConfiguration: updatePdlSearchDataModuleConfigurationHandler,
    updatePceSearchDataModuleConfiguration: updatePceSearchDataModuleConfigurationHandler,
  },
})

export const {
  initialFormConfigurationStateUpdate,
  updateCommonConfiguration,
  updateCustomerModuleConfiguration,
  updateCharacteristicsModuleConfiguration,
  updateBankingsModuleConfiguration,
  updateZohoModuleConfiguration,
  updateProviderConfiguration,
  updateOfferConfiguration,
  updateOfferModuleConfiguration,
  addCommonFormErrors,
  deleteCommonFormErrors,
  resetCommonFormErrors,
  updateElectricityMeterModuleConfiguration,
  updateAdditionalSalesModuleConfiguration,
  updateConsumptionModuleConfiguration,
  updatePropaneModuleConfiguration,
  updateGasMeterModuleConfiguration,
  updatePdlSearchDataModuleConfiguration,
  updatePceSearchDataModuleConfiguration,
} = SubscriptionFormConfigurationSlice.actions

export default SubscriptionFormConfigurationSlice.reducer
