import Panel from '@/shared/components/Panel/Panel'
import { Skeleton } from 'baseui/skeleton'
import useZohoMcpUnlocker from './ModulesPanel/Modules/ZohoModule/ZohoMcpUnlocker/useZohoMcpUnlocker'
import { useSelector } from 'react-redux'
import {
  selectCommonFormConfigurationData,
  selectZohoModuleConfigurationData,
} from './SubscriptionFormConfigurationSelectors'
import { selectCommonSubscriptionData } from './SubscriptionFormSelectors'

const useFormStatus = () => {
  const {
    formLoaded,
    isEditable,
    canSendSubscription,
    formCanBeSubmitted,
    formSubmitted,
    isRetracted,
    mandatoryFields,
    errors,
    lastSentDate,
    missingFieldsDetails,
  } = useSelector(selectCommonFormConfigurationData)
  const { contractLoaded } = useSelector(selectCommonSubscriptionData)
  const { isValidZohoId } = useSelector(selectZohoModuleConfigurationData)
  const { blockedForAgent } = useZohoMcpUnlocker()

  const LoadingPanel = (id, title, alt, logo, setLayouts, layouts) => {
    return (
      <Panel logo={logo} alt={alt} title={title} id={id} setLayouts={setLayouts} layouts={layouts}>
        <Skeleton
          animation
          rows={3}
          width='100%'
          overrides={{
            Row: {
              style: {
                height: '3rem',
                marginBottom: '1rem',
              },
            },
          }}
        />
      </Panel>
    )
  }

  const EmptyPanel = (id, setLayouts, layouts) => {
    return <Panel id={id} setLayouts={setLayouts} layouts={layouts} empty={true} />
  }

  return {
    formIsLoaded: formLoaded === true && contractLoaded === true,
    isEditable,
    canSendSubscription,
    formCanBeSubmitted,
    formSubmitted,
    isRetracted,
    mandatoryFields,
    missingFieldsDetails,
    lastSentDate,
    errors,
    zohoIsInValid: !isValidZohoId || blockedForAgent,
    LoadingPanel,
    EmptyPanel,
  }
}

export default useFormStatus
