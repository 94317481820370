export default function routesConfig(page) {
  switch (page) {
    case 'call-reasons':
      return '/:type/call-reasons'
    case 'services-status':
      return '/:type/services-status'
    case 'subscriptions-list-telesales':
      return '/:type/subscription/list/overview'
    case 'subscriptions-list-websales':
      return '/:type/subscription/list/websales'
    case 'telesales-form':
      return '/:type/subscription/*'
    case 'telesales-form-v2':
      return '/contact/*'
    case 'dev-tools':
      return '/:type/dev-tools/tools'
    case 'call-information':
      return '/call-information/*'
    case 'assistances-management':
      return '/:type/additional-sales/assistances'
    case 'mon-conseiller-perso-management':
      return '/:type/additional-sales/mon-conseiller-perso'
    case 'carbon-offset':
      return '/:type/additional-sales/carbon-offset'
    case 'organizations-management':
      return '/:type/organisations/provider/*'
    case 'provider-options':
      return '/:type/provider-options/*'
    case 'users-management':
      return '/users-mngt/*'
    case 'ip-addresses-management':
      return '/ip-addresses-management/*'
    case 'offices-management':
      return '/:type/offices/*'
    case 'contact-page':
      return '/contact-page/*'
    default:
      return '/:type'
  }
}
