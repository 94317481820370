import { call, select, put } from 'redux-saga/effects'
import { selectAuthData } from '@/containers/Login/AuthSelectors'
import { selectPageConfigurationData } from '@/store/PageConfiguration/PageConfigurationSelectors'
import getAPIConfig from '@/shared/tools/getAPIConfig'
import { updateGasMeterModuleConfiguration } from '../../../SubscriptionFormConfigurationSlice'

export const pceModuleActions = {
  METER_MODULE_CHECK_DUPLICATED: 'METER_MODULE_CHECK_DUPLICATED',
  METER_MODULE_REQUEST_DATA: 'METER_MODULE_REQUEST_DATA',
  METER_MODULE_VALIDATE_METER_SYNTAX: 'METER_MODULE_VALIDATE_METER_SYNTAX',
}

export function* checkDuplicatedMeter({ meterId, energyType, duplicatedCallback }) {
  const { authTokenSession, lang } = yield select(selectAuthData)
  const { pod_validate_duplicated_url: url } = yield select(selectPageConfigurationData)
  const fullUrl = `${url}?lang=${lang}&meterId=${meterId}&energyType=${energyType}`
  try {
    const config = yield call(getAPIConfig, { method: 'get', authTokenSession })
    const response = yield call(fetch, fullUrl, config)
    const result = yield call([response, response.json])
    if (response.status === 200) {
      yield call(duplicatedCallback, result)
    } else {
      console.error('checkDuplicatedMeterError', result, response.status)
      yield call(duplicatedCallback, null)
    }
  } catch (error) {
    console.warn('checkDuplicatedMeterError', error)
  }
}

export function* requestMeterData({ body }) {
  const { authTokenSession, lang } = yield select(selectAuthData)
  const { pod_eligibility_url: url } = yield select(selectPageConfigurationData)
  try {
    yield put(
      updateGasMeterModuleConfiguration({
        pceEligibilityLoading: true,
        pceEligibilityData: {},
      }),
    )
    const config = yield call(getAPIConfig, { method: 'post', authTokenSession, body })
    const response = yield call(fetch, url + '?lang=' + lang, config)
    yield call([response, response.json])
  } catch (error) {
    console.warn('requestMeterDataError', error)
  }
}

export function* validateMeterSyntax({ meterId, callback }) {
  const { authTokenSession, lang } = yield select(selectAuthData)
  const { pod_validate_url: url } = yield select(selectPageConfigurationData)
  const fullUrl = `${url}?lang=${lang}&meterId=${meterId}`
  try {
    const config = yield call(getAPIConfig, { method: 'get', authTokenSession })
    const response = yield call(fetch, fullUrl, config)
    const result = yield call([response, response.json])
    if (response.status === 200) {
      yield call(callback, result)
    } else {
      console.error('validateMeterSyntaxError', result, response.status)
    }
  } catch (error) {
    console.warn('validateMeterSyntaxError', error)
  }
}
