import PropTypes from 'prop-types'
import { useStyletron } from 'baseui'

import { SIZE } from 'baseui/button'
import { ParagraphXSmall } from 'baseui/typography'
import SDButton from '@/shared/components/SDComponents/SDButton/SDButton'
import { useTranslation } from 'react-i18next'
import HOPNotification from '@/shared/components/HOPComponents/HOPNotification'

const Disclaimer = ({ checked, errorText, mandatory, setChecked, disabled }) => {
  const { t } = useTranslation()
  const [css] = useStyletron()
  const buttonText = checked
    ? t('subscriptionForm:customer_module.data_authorization.authorised')
    : t('subscriptionForm:customer_module.data_authorization.authorise')
  const authStyle = css({ margin: '.5rem 0 1rem' })

  if (checked) {
    return (
      <ParagraphXSmall className={authStyle}>
        {t('subscriptionForm:customer_module.data_authorization.caption')}
      </ParagraphXSmall>
    )
  }

  return (
    <HOPNotification type='agentScript' primaryText={t('subscriptionForm:customer_module.data_authorization.label')}>
      <SDButton
        id='button-authorize-customer-module'
        overrides={{
          BaseButton: {
            style: {
              width: '100%',
              marginTop: '1rem',
            },
          },
        }}
        disabled={disabled}
        size={SIZE.compact}
        onClick={event => {
          event.preventDefault()
          setChecked(true)
        }}
      >
        {buttonText}
      </SDButton>
    </HOPNotification>
  )
}

Disclaimer.propTypes = {
  checked: PropTypes.bool,
  errorText: PropTypes.string,
  mandatory: PropTypes.string,
  setChecked: PropTypes.func,
  disabled: PropTypes.bool,
}

export default Disclaimer
