import PropTypes from 'prop-types'
import { FormControl } from 'baseui/form-control'

import SDButton from '@/shared/components/SDComponents/SDButton/SDButton'
import useZohoMcpUnlocker from './useZohoMcpUnlocker'
import { SIZE } from 'baseui/input'

const ZohoMcpUnlocker = () => {
  const { unlockZohoMcp, isBlockedBs, unlockError, isMcpAgent, isAdmin, buttonLabel } = useZohoMcpUnlocker()

  if (!isBlockedBs || (isMcpAgent && !isAdmin)) return null

  return (
    <FormControl error={unlockError}>
      <SDButton size={SIZE.compact} block={false} onClick={unlockZohoMcp} disabled={isAdmin === false}>
        {buttonLabel}
      </SDButton>
    </FormControl>
  )
}

ZohoMcpUnlocker.propTypes = {
  logo: PropTypes.string,
  zohoDossierUrl: PropTypes.string,
}

export default ZohoMcpUnlocker
